.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25%;
  width: 800px;
  max-width: 90%;
  margin: 100px auto;
  padding: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.profile-header h1 {
  text-align: center;
  font-size: 1.5rem;

  font-weight: 500;
}

.profile-header h2 {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 20px;
  font-weight: 400;
}

.profile-header h3 {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
}

.profile-header-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 360px;
  min-height: 100vh;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--neutral);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  width: 90%;

  margin: 0 auto 0 auto;
}

.profile-input {
  background-color: #fff;
  width: 270px;
}

@media screen and (max-width: 768px) {
  .profile-container {
    padding: 0 1rem;
    margin: 0 auto;
    width: 100%;
  }

  .profile-input {
    max-width: 90%;
  }
}

.profile-description {
  font-family: "Roboto", sans-serif;
  font-size: 1.15rem;
  font-weight: 400;
  text-align: center;
  margin: 20px auto;
}
