.about-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  justify-content: center;
}

.about-text p {
  font-size: 1.4rem;
  width: 80%;
  max-width: 800px;
  margin: 20px auto;
  color: #000;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  line-height: 1.25;
}

.text-container {
  max-height: 100%;
  width: fit-content;
  margin: auto;
}

@media screen and (min-width: 979px) {
  .about-text {
    margin: auto;

    justify-content: center;
  }
}
@media screen and (max-width: 978px) {
  .about-text {
    width: 100%;
  }
  .about-text p {
    font-size: 1.25rem;
    margin: 20px auto;
    width: 90%;
  }
}

.about-wrapper {
  /*   display: grid;
  grid-template-columns: 0.75fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px; */
  display: flex;
  flex-direction: row;
  max-height: 100%;
  background-color: #fff;
}

/* .div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
  background-color: #fff;
} */

@media screen and (max-width: 768px) {
  .about-text p {
    font-size: 1.15rem;
  }

  /*   .about-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.35fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .div2 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div1 {
    grid-area: 2 / 1 / 3 / 2;
  } */
}

@media screen and (max-width: 576px) {
  .about-text p {
    font-size: 1.15rem;
  }
}

.card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

.about-header {
  display: flex;
  flex-direction: row;
  font-optical-sizing: auto;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  text-align: center;
  width: fit-content;
  margin: auto auto 40px auto;
  color: #4061d7;
}

@media (min-width: 1200px) {
  .about-header {
    font-size: 4.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about-header {
    margin: 10px auto;
    font-size: 3.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-header {
    margin: 10px auto;
    font-size: 3rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .about-header {
    margin: 10px auto 50px auto;
    font-size: 2.75rem;
  }
}

@media (min-width: 439px) and (max-width: 575px) {
  .about-header {
    margin: 10px auto 40px auto;
    font-size: 3rem;
  }
}

@media (min-width: 361px) and (max-width: 439px) {
  .about-header {
    margin: 10px auto 20px auto;
    font-size: 2.75rem;
  }
}

@media (min-width: 341px) and (max-width: 360px) {
  .about-header {
    margin: 10px auto 10px auto;
    font-size: 2.25rem;
  }
}

@media (max-width: 340px) {
  .about-header {
    margin: 10px auto 10px auto;
    font-size: 1.5rem;
  }
}

.sm-img {
  display: none;
}

.lg-img {
  object-fit: cover;
  display: block;
  object-fit: cover;
  height: 100%;
}

.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
}

@media screen and (max-width: 978px) {
  .lg-img {
    display: none;
  }

  .sm-img {
    display: block;
    width: fit-content;
    margin: 25px auto auto auto;
  }
}

@media screen and (max-width: 568px) {
  .about-text p {
    font-size: 1.1rem;
    max-width: 340px;
  }
}

.about-header-wrapper {
  display: flex;
  width: 100%;
  height: 100px;
  background-color: #fff;
  justify-content: center;
}
