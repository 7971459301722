.cv-wrapper {
  margin: 25px auto 25px auto;
  padding: 25px 100px;
  background-color: #fff;
  justify-content: center;
  border-style: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-self: center;
  min-height: fit-content;
  max-width: 1000px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  width: 100%;
  height: 100vh;
  padding-bottom: 40px;
  min-height: fit-content;
}

.grid-item1 {
  grid-area: 1 / 1 / 2 / 2;
}

.grid-item2 {
  grid-area: 1 / 2 / 2 / 3;
}

@media screen and (max-width: 768px) {
  .cv-wrapper {
    padding: 20px;
    height: 80%;
    min-height: fit-content;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 10px;
  }
  .grid-item1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-item2 {
    grid-area: 2 / 1 / 3 / 2;
  }
}

p {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.projects li {
  margin-bottom: 10px;
  color: #000;
}
ul {
  list-style-type: none;
  padding: 0;
}

h3 {
  margin-top: 40px;
}

h4 {
  font-size: 22px;
  font-weight: 500;
}

h4 span {
  font-size: 18px;
  font-style: italic;
}
