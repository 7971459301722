:root {
  --darkest-blue: #21387a;
  --dark-blue: #4061d6;
  --blue: #598cfa;
  --light-blue: #59abfa;
  --lightest-blue: #d6e5f5;
  --neutral: #f2f4fc;

  --dark-violet: #1700eb;
  --violet: #7366f0;
  --light-violet: #abb6eb;

  --dark-yellow: #c48404;
  --yellow: #fcb529;
  --light-yellow: #fece77;

  --dark-green: #3f9c99;
  --green: #66c8b6;
  --light-green: #77e4c6;

  --dark-berry: #9e1b4a;
  --berry: #d54d68;
  --light-berry: #e57c7e;
}

/* Color Classes */
.light-blue {
  color: #59abfa;
}
.blue {
  color: #4061d7;
}
.dark-blue {
  color: #21387a;
}
.orange {
  color: #fd9e2a;
}

/* Typography */
#top {
  font-weight: 600;
}
h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 3.5% auto 0;
}

/* Layout */
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  height: calc(100% - 50px);
  overflow: auto;
  scrollbar-width: none;
  background-color: #fff;
}
.about-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5%;
  justify-content: space-between;
}
.splash {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 25vh auto;
  font-family: "Outfit", sans-serif;
}
.splash-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  justify-content: center;
  scrollbar-width: none;
}

#splash-container-dk {
  background-color: var(--darkest-blue);
}
/* Header */
.header,
#header-pm-div {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0;
  padding: 0 20px;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.07em;
}
.header {
  max-width: 90vw;
}
#header-pm-div {
  max-width: 100vw;
}

/* Header Div */
.header-div,
#header-pm-div {
  width: fit-content;
  line-height: 0.95;
  margin: 5px 2.5px;
  text-wrap: nowrap;
  font-size: 5rem;
}

@media (min-width: 770px) and (max-width: 1200px) {
  .header-div,
  #header-pm-div {
    font-size: 4.5rem;
  }
}
@media (min-width: 440px) and (max-width: 770px) {
  .header-div,
  #header-pm-div {
    font-size: 3rem;
  }
}
@media (min-width: 376px) and (max-width: 439px) {
  .header-div,
  #header-pm-div {
    font-size: 2.5rem;
  }
}
@media (max-width: 375px) {
  .header-div,
  #header-pm-div {
    font-size: 2rem;
  }
}

/* Header Sub */
.header-sub {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 85%;
  justify-content: space-between;
  margin: 40px auto;
}
.header-sub-div {
  width: fit-content;
  margin: 0 5px;
  color: #21387a;
  padding-bottom: 10px;
  border: 3px none #fff;
  background-color: transparent;
  transition: all 0.5s;
}
.header-sub-div:hover {
  color: #588dfb;
  cursor: pointer;
  border-bottom: 3px solid #22377a;
}

@media (min-width: 770px) {
  .header-sub-div {
    font-size: 1.75rem;
  }
}
@media (max-width: 769px) {
  .header-sub {
    flex-direction: column;
    margin: 40px auto;
  }
  .header-sub-div {
    font-size: 1.5rem;
    margin: 0 auto;
  }
}

@media (max-width: 375px) {
  .header-sub-div {
    font-size: 1.25rem;
  }
}
