.header-wrap {
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.contact-header {
  font-optical-sizing: auto;
  font-size: 3.5rem;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  max-width: 100%;
  text-wrap: nowrap;
  letter-spacing: -0.05em;
  margin: auto;
  color: #21387a;
}

.subheader {
  font-optical-sizing: auto;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  width: fit-content;
  margin: 0 auto 20px auto;
  color: #4061d7;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contact-header {
    margin: 10px auto;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 991px) {
  .contact-header {
    margin: 10px auto;
    font-size: 3rem;
  }
  .subheader {
    margin: 0 auto 10px auto;
  }
}

@media screen and (max-width: 767px) {
  .contact-header {
    margin: 10px auto;
    font-size: 2.75rem;
  }
  .subheader {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 575px) {
  .contact-header {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 439px) {
  .contact-header {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 360px) {
  .contact-header {
    margin: 20px auto 20px auto;
    font-size: 2rem;
  }
}
